import React from "react"

import Layout from "../components/layout"
import SEO from "components/seo"
import LandingPageConatiner from "container/404"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <LandingPageConatiner />
  </Layout>
)

export default NotFoundPage
