import React from "react"
import { Flexbox, Text } from "@ballchy/styled-bits"
import { Overlay } from "atoms"
import Footer from "components/Footer"
import { Link } from "gatsby"

const LandingPageContainer = () => {
  return (
    <>
      <Overlay
        id="landing-page-overlay"
        fontSize={["270px", "270px", "520px"]}
        textAlign="center"
        textTransform="uppercase"
      >
        Chi
      </Overlay>
      <Flexbox
        as="section"
        id="landing-page-container"
        position="relative"
        flexDirection="column"
        minHeight={["40em", "52em", "100vh"]}
        maxWidth="1040px"
        mx="auto"
        px={[3, 3, 0]}
        py={[3, 3, 4]}
      >
        <Flexbox
          id="landing-page-split-screen"
          flex={["0 1 auto", "0 1 auto", 1]}
          justifyContent="center"
          flexDirection="column"
          position="relative"
          overflow="hidden"
        >
          <Text fontSize={[4, 4, 6]}>
            Opps, looks like you landed on a not found page{" "}
            <span role="img" aria-label="scared">
              😰
            </span>
            . Time to hit the return button <Link to="/">here</Link>.
          </Text>
        </Flexbox>

        <Footer />
      </Flexbox>
    </>
  )
}
export default LandingPageContainer
